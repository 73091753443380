import { defineStore } from 'pinia';

interface ShowDialog {
    status: boolean,
    message: string
}

export const useSimpleDialogStore = defineStore('simpleDialog', {
    state: () => ({
        showSimpleDialog: false,
        simpleDialogMessage: ''
    }),
    actions: {
        showDialog(payload: ShowDialog) {
            this.showSimpleDialog = payload.status;
            this.simpleDialogMessage = payload.message;
        },
    },
});