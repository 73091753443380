import { createApp } from 'vue'
import App from './App.vue'
import { createVuestic } from 'vuestic-ui'
import 'vuestic-ui/css'
import { createPinia } from 'pinia'
import router from './router'
import { i18n } from '@/plugins/i18n';
import '@/styles/app.scss';
import vuesticConfig from '@/configs/vuestic';

const pinia = createPinia()

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fass } from '@fortawesome/sharp-solid-svg-icons'
library.add(fad)
library.add(fal)
library.add(far)
library.add(fas)
library.add(fat)
library.add(fass)

createApp(App)
	.use(createVuestic(vuesticConfig))
	.use(pinia)
	.use(router)
	.use(i18n)
	.component('font-awesome-icon', FontAwesomeIcon)
	.mount('#app')
